html {
  color: #fff;
  text-shadow: 0 0 2px #000;
  background: #240024;
  font-family: monospace;
  font-size: 1.5rem;
  line-height: 1.4;
  overflow: hidden;
}

strong {
  text-shadow: 0 0 3px #000, 0 0 5px gold;
  font-weight: 400;
}

a {
  text-shadow: 0 0 3px #000, 0 0 4px #0ff700;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  text-shadow: 0 0 3px #000, 0 0 8px #1fff10;
}

button {
  cursor: pointer;
  color: #fff;
  background-color: #370037;
  border: 1px solid #000;
  padding: .1em;
  text-decoration: none;
  display: inline-block;
}

button:hover {
  background-color: #400040;
}

button:active {
  position: relative;
  top: 1px;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

/*# sourceMappingURL=index.32fa46f5.css.map */
